import { call, put, select } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import md5 from 'md5';
import fp from 'lodash/fp';
import { format } from 'date-fns';

import { setIsLoading, setRejectedLangs } from '../actions/common';
import { API } from '../../api';
import {
  clear,
  setCanCloseModalWindow,
  setCerts,
  setCopyForEdit,
  setDocs,
  setList,
  setListAfterRemove,
  setModerationItemComment,
  setPhoto,
  setProduct,
  setProductCategoryList,
  setProductFirstStepTemplate,
  setSignData,
} from '../actions/products';
import { setGtins, setGtinsError, setGtinsLoading } from '../actions/enums';
import { routes } from '../../routes';
import { prepareComments, prepareDocs, preparePhoto } from '../../utils/utils';
import { isIncorrectInputError } from '../../helpers';
import { EimzoService, prepareAllCertificateList } from '../../lib/Eimzo';
import { Toast } from '../../components/toastWrapper/toastShowWrapper/toast';
import { Translate } from '../../components/translate';
import {
  getProductNewFiles,
  getProductParameters,
} from '../../pages/products/form/utils';
import { setComments } from '../actions/tasks';
import { PAGINATION_DEFAULT } from '../reducers/constants';

import { loadCategoriesListSaga, loadTnvedByCategorySaga } from './enums';

export function* loadGtinListSaga({ payload }) {
  try {
    yield put(setGtinsLoading());
    const json = yield API.rest.product.list(payload);
    yield put(
      setGtins({
        name: 'gtins',
        values: [...json],
      })
    );
  } catch (err) {
    yield put(setGtinsError());
    yield Toast.showError({ errors: err });
  }
}

export function* loadTemplateSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const template = yield API.rest.product.getTemplate(payload.data);
    yield put(setProduct(template));
    yield put(setIsLoading(false));
    yield payload.onFinish(false);
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
    yield payload.onFinish(true);
  }
}
function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function* uploadFilesSaga(files) {
  try {
    const data = files.reduce(
      (acc, item) => {
        const fileName = item?.externalId
          ? `${item?.externalId}_${item.file.name}`
          : item.file.name;
        acc.file.push(renameFile(item.file, fileName));
        acc.metaData.files[fileName] = {
          externalId: item?.externalId,
        };
        return acc;
      },
      { file: [], metaData: { files: {} } }
    );

    const formData = new window.FormData();
    for (let file of data.file) {
      formData.append('file', file);
    }
    formData.append(
      'meta-data',
      new Blob([JSON.stringify(data.metaData)], {
        type: 'application/json',
      })
    );
    return yield API.rest.product.uploadFiles(formData, '');
  } catch (err) {
    yield Toast.showError({ errors: err });
    return {};
  }
}

export function* updateProductDraftSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const {
      data,
      history,
      removedPhotos,
      isEditingNC,
      isEditingByOperator,
      filter,
      attributes_units,
    } = payload;
    const newFiles = getProductNewFiles(data);

    let updatedFiles = [];
    if (newFiles.length) {
      updatedFiles = yield call(uploadFilesSaga, newFiles);
    }

    const { newData, id } = getProductParameters(data, updatedFiles);

    Object.keys(removedPhotos).forEach((side) => {
      if (!newData?.[side]) {
        newData[side] = null;
      }
    });
    if (!newData?.technical_photo?.files) {
      newData['technical_photo'] = null;
    }

    const newDataFiltered = fp.pipe(fp.omitBy(filter))(newData);

    isEditingNC || isEditingByOperator
      ? yield API.rest.product.updateNCDraft(id, {
          attributes: newDataFiltered,
          attributes_units,
        })
      : yield API.rest.product.updateDraft(id, {
          tnved: data.tnved,
          attributes: newDataFiltered,
          attributes_units,
        });

    yield put(setIsLoading(false));
    history.push(routes.products());
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* createProductDraftSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const { data, history } = payload;
    const newFiles = getProductNewFiles(data.attributes);
    let updatedFiles = [];
    if (newFiles.length) {
      updatedFiles = yield call(uploadFilesSaga, newFiles);
    }

    const { newData } = getProductParameters(data.attributes, updatedFiles);
    yield API.rest.product.createDraft({
      ...data,
      attributes: newData,
    });
    yield put(setIsLoading(false));
    history.push(routes.products());
  } catch (err) {
    console.error('err', err);
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* getProductFirstStepTemplateSaga() {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.attributes.firstStepTemplate();
    yield put(setProductFirstStepTemplate(json));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* getProductSampleSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const json = yield API.rest.product.item(payload);
    if (json?.product_group?.code) {
      yield call(loadCategoriesListSaga, {
        payload: json?.product_group?.code,
      });
    }
    if (json?.category) {
      yield call(loadTnvedByCategorySaga, {
        payload: json?.category,
      });
    }
    yield put(setProduct(json));
    const photo = preparePhoto([
      ...json?.mandatory_attributes,
      ...json?.optional_attributes,
    ]);

    const docs = prepareDocs(json);
    yield put(setDocs(docs));

    yield put(setPhoto(photo));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* getProductCopyForEditSaga({ payload }) {
  try {
    const json = yield API.rest.product.getCopyForEdit(payload);
    yield put(push(routes.products(`edit-published/${json.id}`)));
    yield put(
      setCopyForEdit({
        ...json,
      })
    );
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}

export function* blockProductSaga({ payload }) {
  try {
    const { id, tab } = payload;
    yield put(setIsLoading(true));
    yield API.rest.product.blockDraft(id);
    if (tab) {
      yield call(loadGoodsListAfterRemoveSaga, { payload: { tab } });
    } else {
      yield call(getProductSampleSaga, { payload: id });
    }
    yield put(setIsLoading(false));
    yield Toast.showSuccess({
      content: Translate('Карточка товара заблокирована'),
    });
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadGoodsListAfterRemoveSaga({ payload }) {
  const { tab } = payload;
  const { pagination, filter } = yield select((state) => state.products);
  yield call(loadGoodsListSaga, {
    payload: {
      pagination: {
        ...pagination,
        limit: pagination.limit + pagination.offset,
        offset: PAGINATION_DEFAULT.offset,
        page: PAGINATION_DEFAULT.page,
      },
      filter: filter[tab],
      afterRemove: true,
    },
  });
}

export function* loadGoodsListSaga({ payload }) {
  try {
    yield put(setIsLoading(true));
    const { dateFrom, dateTo } = payload.filter;
    const newFilter = { ...payload.filter };

    if (dateFrom) {
      newFilter.dateFrom = format(new Date(dateFrom), 'yyyy-MM-dd');
    }
    if (dateTo) {
      newFilter.dateTo = format(new Date(dateTo), 'yyyy-MM-dd');
    }

    const json = yield API.rest.product.list({
      ...newFilter,
      ...payload.pagination,
      ...payload.sorting,
    });
    if (payload?.afterRemove) {
      yield put(setListAfterRemove(json));
    } else {
      yield put(setList(json));
    }
    yield put(setIsLoading(false));
  } catch (err) {
    if (isIncorrectInputError(err)) {
      yield put(clear());
    } else {
      yield put(
        push(
          routes.serverError('', {
            code: err?.response?.status || 500,
          })
        )
      );
    }
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* removeDraftSaga(action) {
  try {
    yield put(setIsLoading(true));
    yield API.rest.product.removeDraft(action.payload);
    yield call(loadGoodsListAfterRemoveSaga, { payload: { tab: 'DRAFT' } });
    yield put(setCanCloseModalWindow(true));
    yield put(setIsLoading(false));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* loadSignData({ payload }) {
  const id = payload;
  try {
    const json = yield API.rest.product.getSingData(id);
    yield put(setSignData(json));
  } catch (e) {
    yield Toast.showError({ errors: e });
  }
}

export function* loadCerts({ payload }) {
  try {
    const id = payload;
    yield EimzoService.init();
    const certkey = yield EimzoService.enumerateCertificates(
      'list_all_certificates',
      'certkey',
      null
    );
    const pfx = yield EimzoService.enumerateCertificates(
      'list_all_certificates',
      'pfx',
      null
    );
    const ftjc = yield EimzoService.enumerateCertificates(
      'list_all_keys',
      'ftjc',
      ['']
    );
    const certList = prepareAllCertificateList(certkey, pfx, ftjc);
    if (certList.length) {
      const certs = certList
        .filter((el) => el.validTo.getTime() > new Date().getTime())
        .map((el) => ({
          label: `${el.name} - ${el.position}`,
          value: el.id,
          cert: el,
        }));
      yield put(setCerts(certs));
      if (id) {
        yield call(loadSignData, {
          payload: id,
        });
      }
    }
  } catch (e) {
    if (e) {
      yield Toast.showError({ errors: e });
    }
  }
}

export function* moderateProductSaga({ payload }) {
  const { id, certificate, withoutCert, onCancel, noVerification } = payload;
  try {
    const signData = yield select((state) => state.products.signData);
    const body = {};
    if (signData) {
      if (withoutCert) {
        body.signature = md5(signData);
      } else if (!noVerification) {
        const key = yield EimzoService.loadKey(certificate);
        const sign = yield EimzoService.sign(key.keyId, signData, true);
        const withTimestamp = yield EimzoService.getTimestampSign(
          sign.pkcs7_64
        );
        if (!withTimestamp) {
          throw new Error('Timestamp sign error');
        }
        body.signature = withTimestamp?.pkcs7b64;
      }
    }
    yield put(setIsLoading(true));
    yield API.rest.product.toModerate(id, body);
    yield Toast.showSuccess({
      content: Translate('Товар отправлен на модерацию!'),
    });
    yield put(setRejectedLangs([]));
    const json = yield API.rest.product.item(id);
    yield put(setProduct(json));
    onCancel();
    yield put(setIsLoading(false));
  } catch (e) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: e });
    onCancel();
  }
}

export function* getCommentsAfterModerationSaga({ payload }) {
  try {
    const json = yield API.rest.product.getComments(payload);
    const commentObj = prepareComments(json?.comments || []);
    yield put(setModerationItemComment(commentObj));
    yield put(setComments(commentObj));
  } catch (err) {
    yield put(setIsLoading(false));
    yield Toast.showError({ errors: err });
  }
}

export function* getProductCategoryList() {
  try {
    const json = yield API.rest.category.list();
    yield put(setProductCategoryList(json));
  } catch (err) {
    yield Toast.showError({ errors: err });
  }
}
