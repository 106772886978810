export type Types = typeof types;

export type ActionType = Types[keyof Types];

export const types = {
  SET_IS_LOADING: '[SAGS/SETTINGS]/SET_IS_LOADING',
  SET_COMMON_SETTINGS: '[SAGS/SETTINGS]/SET_COMMON_SETTINGS',
  SET_LANGUAGE: '[SAGS/SETTINGS]/SET_LANGUAGE',
  SET_TASKS_TABS: '[SAGS/SETTINGS]/SET_TASKS_TABS',

  DOWNLOAD_FILE: '[SAGS/FILES]/DOWNLOAD_FILE',
  UPLOAD_FILE: '[SAGS/FILES]/UPLOAD_FILE',

  LOGIN: '[SAGS/AUTH]/LOGIN',
  LOGOUT: '[SAGS/AUTH]/LOGOUT',
  SET_AUTH_DATA: '[SAGS/AUTH]/SET_AUTH_DATA',
  SET_USER_DATA: '[SAGS/AUTH]/SET_USER_DATA',
  CLEAR_AUTH_DATA: '[SAGS/AUTH]/CLEAR_AUTH_DATA',
  RECONNECT_AUTH_DATA: '[SAGS/AUTH]/RECONNECT_AUTH_DATA',
  LOGOUT_BY_BLOCK: '[SAGS/AUTH]/LOGOUT_BY_BLOCK',
  CHECK_AUTH_DATA: '[SAGS/AUTH]/CHECK_AUTH_DATA',
  GET_CURRENT_USER: '[SAGS/AUTH]/GET_CURRENT_USER',
  SET_CURRENT_USER: '[SAGS/AUTH]/SET_CURRENT_USER',
  CLEAR_CURRENT_USER: '[SAGS/AUTH]/CLEAR_CURRENT_USER',
  CHECK_EXP_DATE: '[SAGS/AUTH]/CHECK_EXP_DATE',

  SET_PARTICIPANTS: '[SAGS/PARTICIPANTS]/SET_PARTICIPANTS',
  PARTICIPANTS_GET_LIST: '[SAGS/PARTICIPANTS]/GET_LIST',
  PARTICIPANTS_SET_LIST: '[SAGS/PARTICIPANTS]/SET_LIST',
  PARTICIPANTS_GET_ITEM: '[SAGS/PARTICIPANTS]/GET_ITEM',
  PARTICIPANTS_SET_ITEM: '[SAGS/PARTICIPANTS]/SET_ITEM',
  CLEAR_PARTICIPANTS: '[SAGS/PARTICIPANTS]/CLEAR_PARTICIPANTS',
  BLOCK_PARTICIPANTS: '[SAGS/PARTICIPANTS]/BLOCK_PARTICIPANTS',
  UPDATE_PARTICIPANTS: '[SAGS/PARTICIPANTS]/UPDATE_PARTICIPANTS',
  GET_PARTICIPANT_PRODUCT: '[SAGS/PARTICIPANTS]/GET_PARTICIPANT_PRODUCT',
  SET_PARTICIPANT_PRODUCT: '[SAGS/PARTICIPANTS]/SET_PARTICIPANT_PRODUCT',

  SET_FIDUCIARY_PARTICIPANTS: '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_PARTICIPANTS',
  FIDUCIARY_PARTICIPANT_GET_ITEM: '[SAGS/FIDUCIARY_PARTICIPANTS]/GET_ITEM',
  FIDUCIARY_PARTICIPANT_SET_ITEM: '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_ITEM',

  FIDUCIARY_PARTICIPANT_DELETE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/FIDUCIARY_PARTICIPANT_DELETE',

  FIDUCIARY_PARTICIPANTS_GET_LIST: '[SAGS/FIDUCIARY_PARTICIPANTS]/GET_LIST',
  FIDUCIARY_PARTICIPANTS_SET_LIST: '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_LIST',

  CREATE_FIDUCIARY_PARTICIPANT:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/CREATE_FIDUCIARY_PARTICIPANT',
  UPDATE_FIDUCIARY_PARTICIPANT:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/UPDATE_FIDUCIARY_PARTICIPANT',

  FIDUCIARY_PARTICIPANTS_GET_PRODUCTS:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/GET_PRODUCTS',
  FIDUCIARY_PARTICIPANTS_SET_PRODUCTS:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_PRODUCTS',
  FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_STATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_PRODUCTS_STATE',
  FIDUCIARY_PARTICIPANTS_CLEAR_PRODUCTS_STATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/CLEAR_PRODUCTS_STATE',

  FIDUCIARY_PARTICIPANTS_GET_PRODUCTS_FOR_CREATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/GET_PRODUCTS_FOR_CREATE',
  FIDUCIARY_PARTICIPANTS_SET_PRODUCTS_FOR_CREATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_PRODUCTS_FOR_CREATE',
  FIDUCIARY_PARTICIPANTS_SET_STATE_PRODUCTS_FOR_CREATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_STATE_PRODUCTS_FOR_CREATE',
  FIDUCIARY_PARTICIPANTS_CLEAR_STATE_PRODUCTS_FOR_CREATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/CLEAR_STATE_PRODUCTS_FOR_CREATE',
  FIDUCIARY_PARTICIPANTS_SET_TRUSTED_PARTICIPANT:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_TRUSTED_PARTICIPANT',

  CLEAR_FIDUCIARY_PARTICIPANTS:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/CLEAR_PARTICIPANTS',

  CLEAR_FIDUCIARY_PARTICIPANTS_STATE:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/CLEAR_PARTICIPANTS_STATE',
  SET_POSSIBLE_TRUSTED_PARTICIPANTS:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/SET_POSSIBLE_TRUSTED_PARTICIPANTS',
  GET_POSSIBLE_TRUSTED_PARTICIPANTS:
    '[SAGS/FIDUCIARY_PARTICIPANTS]/GET_POSSIBLE_TRUSTED_PARTICIPANTS',

  SET_PREFIXES: '[SAGS/PREFIXES]/SET_PREFIXES',
  PREFIXES_GET_LIST: '[SAGS/PREFIXES]/GET_LIST',
  PREFIXES_GET_LIST_MY: '[SAGS/PREFIXES]/GET_LIST_MY',
  PREFIXES_SET_LIST: '[SAGS/PREFIXES]/SET_LIST',
  PREFIXES_SET_ITEM: '[SAGS/PREFIXES]/SET_ITEM',
  CLEAR_PREFIXES: '[SAGS/PREFIXES]/CLEAR_PREFIXES',
  BLOCK_PREFIXES: '[SAGS/PREFIXES]/BLOCK_PREFIXES',
  DELETE_PREFIXES: '[SAGS/PREFIXES]/DELETE_PREFIXES',
  CREATE_PREFIXES: '[SAGS/PREFIXES]/CREATE_PREFIXES',
  PREFIX_CREATED: '[SAGS/PREFIXES]/PREFIX_CREATED',

  SET_GOODS: '[SAGS/PARTICIPANTS]/SET_GOODS',
  GOODS_GET_LIST: '[SAGS/GOODS]/GET_LIST',
  GOODS_SET_LIST: '[SAGS/GOODS]/SET_LIST',
  GOODS_REMOVE_ITEM_FROM_LIST: '[SAGS/GOODS]/GOODS_REMOVE_ITEM_FROM_LIST',
  GOODS_CLEAR_LIST: '[SAGS/GOODS]/CLEAR_LIST',
  REMOVE_DRAFT: '[SAGS/GOODS]/REMOVE_DRAFT',
  SET_CAN_CLOSE_MODAL_WINDOW: '[SAGS/GOODS]/SET_CAN_CLOSE_MODAL_WINDOW',

  TNVED_GET_LIST: '[SAGS/TNVED]/GET_LIST',
  TNVED_SET_LIST: '[SAGS/TNVED]/SET_LIST',

  CATEGORIES_GET_LIST: '[SAGS/CATEGORIES]/CATEGORIES_GET_LIST',
  TNVED_BY_CATEGORIES_GET_LIST:
    '[SAGS/CATEGORIES]/TNVED_BY_CATEGORIES_GET_LIST',
  PG_BY_CATEGORIES_GET_LIST: '[SAGS/CATEGORIES]/PG_BY_CATEGORIES_GET_LIST',

  NATIONAL_GET_LIST: '[SAGS/NATIONAL]/GET_LIST',
  NATIONAL_SET_LIST: '[SAGS/NATIONAL]/SET_LIST',

  ENUM_GET_LIST: '[SAGS/ENUM]/GET_LIST',
  ENUM_SET_LIST: '[SAGS/ENUM]/SET_LIST',

  GET_GTINS: '[SAGS/ENUM]/GET_GTINS',
  SET_GTINS: '[SAGS/ENUM]/SET_GTINS',
  SET_GTINS_LOADING: '[SAGS/ENUM]/SET_GTINS_LOADING',
  SET_GTINS_ERROR: '[SAGS/ENUM]/SET_GTINS_ERROR',
  CLEAR_GTINS: '[SAGS/ENUM]/CLEAR_GTINS',
  GET_TYPICAL_COMMENTS: '[SAGS/ENUM]/GET_TYPICAL_COMMENTS',
  GET_PRODUCT_GROUPS_ENUM: '[SAGS/ENUM]/GET_PRODUCT_GROUPS_ENUM',
  GET_PRODUCT_CATEGORIES_ENUM: '[SAGS/ENUM]/GET_PRODUCT_CATEGORIES_ENUM',
  TEMPLATE_GET: '[SAGS/TEMPLATE]/GET',
  SET_PRODUCT: '[SAGS/PRODUCT]/SET',
  CLEAR_PRODUCT: '[SAGS/PRODUCT]/CLEAR',
  CLEAR_PRODUCT_FILTER: '[SAGS/PRODUCT]/CLEAR_PRODUCT_FILTER',
  CLEAR_PRODUCT_FILTER_BY_ID: '[SAGS/PRODUCT]/CLEAR_PRODUCT_FILTER_BY_ID',
  SET_MODERATION_ITEM: '[SAGS/PRODUCT]/SET_MODERATION_ITEM',
  SET_MODERATION_BASE_INFO_ITEM: '[SAGS/PRODUCT]/SET_MODERATION_BASE_INFO_ITEM',
  SET_MODERATION_PHOTO: '[SAGS/PRODUCT]/SET_MODERATION_PHOTO',
  SET_MODERATION_DOC: '[SAGS/PRODUCT]/SET_MODERATION_DOC',
  SET_MODERATION_ITEM_COMMENT: '[SAGS/PRODUCT]/SET_MODERATION_ITEM_COMMENT',
  DELETE_MODERATION_ITEM_COMMENT:
    '[SAGS/PRODUCT]/DELETE_MODERATION_ITEM_COMMENT',
  TOGGLE_GALLERY: '[SAGS/PRODUCT]/TOGGLE_GALLERY',
  UPDATE_PRODUCT_DRAFT: '[SAGS/PRODUCT]/UPDATE_PRODUCT_DRAFT',
  CREATE_PRODUCT_DRAFT: '[SAGS/PRODUCT]/CREATE_PRODUCT_DRAFT',
  GET_PRODUCT_FIRST_STEP_TEMPLATE:
    '[SAGS/PRODUCT]/GET_PRODUCT_FIRST_STEP_TEMPLATE',
  SET_PRODUCT_FIRST_STEP_TEMPLATE:
    '[SAGS/PRODUCT]/SET_PRODUCT_FIRST_STEP_TEMPLATE',
  GET_PRODUCT_SAMPLE: '[SAGS/PRODUCT]/GET_PRODUCT_SAMPLE',
  GET_PRODUCT_CATEGORY_LIST: '[SAGS/PRODUCT]/GET_PRODUCT_CATEGORY_LIST',
  SET_PRODUCT_CATEGORY_LIST: 'SET_PRODUCT_CATEGORY_LIST',
  SET_PRODUCT_SAMPLE_AFTER_CHANGE_PHOTO:
    '[SAGS/PRODUCT]/SET_PRODUCT_SAMPLE_AFTER_CHANGE_PHOTO',
  BLOCK_PRODUCT: '[SAGS/PRODUCT]/BLOCK_PRODUCT',
  GET_PHOTO: '[SAGS/PRODUCT]/GET_PHOTO',
  SET_PHOTO: '[SAGS/PRODUCT]/SET_PHOTO',
  SET_DOCS: '[SAGS/PRODUCT]/SET_DOCS',
  UPLOAD_FILES: '[SAGS/PRODUCT]/UPLOAD_FILES',
  GET_COPY_FOR_EDIT: '[SAGS/PRODUCT]/GET_COPY_FOR_EDIT',
  SET_COPY_FOR_EDIT: '[SAGS/PRODUCT]/SET_COPY_FOR_EDIT',
  GET_ORIGINAL_LANGS: '[SAGS/PRODUCT]/GET_ORIGINAL_LANGS',
  SET_ORIGINAL_LANGS: '[SAGS/PRODUCT]/SET_ORIGINAL_LANGS',
  SET_SIGN_DATA: '[SAGS/PRODUCT]/SET_SIGN_DATA',
  TO_MODERATE_PRODUCT: '[SAGS/PRODUCT]/TO_MODERATE_PRODUCT',
  GET_CERTS: '[SAGS/PRODUCT]/GET_CERTS',
  SET_CERTS: '[SAGS/PRODUCT]/SET_CERTS',
  GET_MODERATION_COMMENTS: '[SAGS/PRODUCT]/GET_MODERATION_COMMENTS',
  PRODUCTS_SET_LIST_AFTER_REMOVE: '[SAGS/PRODUCTS]/SET_LIST_AFTER_REMOVE',
  GET_TRUSTED_PARTICIPANTS_BY_INN:
    '[SAGS/PRODUCT]/GET_TRUSTED_PARTICIPANTS_BY_INN',
  SET_TRUSTED_PARTICIPANTS_BY_INN:
    '[SAGS/PRODUCT]/SET_TRUSTED_PARTICIPANTS_BY_INN',
  GET_TRUSTED_PARTICIPANTS_BY_NAME:
    '[SAGS/PRODUCT]/GET_TRUSTED_PARTICIPANTS_BY_NAME',
  SET_TRUSTED_PARTICIPANTS_BY_NAME:
    '[SAGS/PRODUCT]/SET_TRUSTED_PARTICIPANTS_BY_NAME',
  GET_TRUSTED_PARTICIPANTS: '[SAGS/PRODUCT]/GET_TRUSTED_PARTICIPANTS',
  SET_TRUSTED_PARTICIPANTS: '[SAGS/PRODUCT]/SET_TRUSTED_PARTICIPANTS',
  UPDATE_TRUSTED_PARTICIPANTS: '[SAGS/PRODUCT]/UPDATE_TRUSTED_PARTICIPANTS',
  DELETE_TRUSTED_PARTICIPANTS: '[SAGS/PRODUCT]/DELETE_TRUSTED_PARTICIPANTS',
  TRUSTED_PARTICIPANTS_GET_LIST: '[SAGS/PRODUCT]/TRUSTED_PARTICIPANTS_GET_LIST',
  TRUSTED_PARTICIPANTS_SET_LIST: '[SAGS/PRODUCT]/TRUSTED_PARTICIPANTS_SET_LIST',
  TRUSTED_PARTICIPANTS_SET: '[SAGS/PRODUCT]/TRUSTED_PARTICIPANTS_SET',
  TRUSTED_PARTICIPANTS_CLEAR: '[SAGS/PRODUCT]/TRUSTED_PARTICIPANTS_CLEAR',

  SET_PRODUCT_GROUPS: '[SAGS/PRODUCT_GROUPS]/SET_PRODUCT_GROUPS',
  GET_PRODUCT_GROUPS_LIST: '[SAGS/PRODUCT_GROUPS]/GET_PRODUCT_GROUPS_LIST',
  SET_PRODUCT_GROUPS_LIST: '[SAGS/PRODUCT_GROUPS]/SET_PRODUCT_GROUPS_LIST',
  CLEAR_PRODUCT_GROUPS_LIST: '[SAGS/PRODUCT_GROUPS]/CLEAR_PRODUCT_GROUPS_LIST',
  GET_PRODUCT_GROUPS_ITEM: '[SAGS/PRODUCT_GROUPS]/GET_PRODUCT_GROUPS_ITEM',
  GET_CODES: '[SAGS/PRODUCT_GROUPS]/GET_CODES',
  SET_CODES: '[SAGS/PRODUCT_GROUPS]/SET_CODES',
  GET_ATTRIBUTES: '[SAGS/PRODUCT_GROUPS]/GET_ATTRIBUTES',
  SET_ATTRIBUTES: '[SAGS/PRODUCT_GROUPS]/SET_ATTRIBUTES',
  GET_PACKAGE_LEVELS: '[SAGS/PRODUCT_GROUPS]/GET_PACKAGE_LEVELS',
  SET_PACKAGE_LEVELS: '[SAGS/PRODUCT_GROUPS]/SET_PACKAGE_LEVELS',
  SET_PACKAGE_TYPE: 'SET_PACKAGE_TYPE',
  GET_PG_CURRENT_CATEGORY: '[SAGS/PRODUCT_GROUPS]GET_PG_CURRENT_CATEGORY',
  SET_PG_CURRENT_CATEGORY: '[SAGS/PRODUCT_GROUPS]SET_PG_CURRENT_CATEGORY',
  GET_PG_CATEGORIES: '[SAGS/PRODUCT_GROUPS]/GET_PG_CATEGORIES',
  SET_PG_CATEGORIES: '[SAGS/PRODUCT_GROUPS]/SET_PG_CATEGORIES',
  SET_PRODUCT_GROUPS_ITEM: '[SAGS/PRODUCT_GROUPS]/SET_PRODUCT_GROUPS_ITEM',
  CLEAR_PRODUCT_GROUPS_ITEM: '[SAGS/PRODUCT_GROUPS]/CLEAR_PRODUCT_GROUPS_ITEM',

  GET_TASKS: '[SAGS/GOODS_MODERATION]/GET_TASKS',
  SET_TASKS_LIST: '[SAGS/GOODS_MODERATION]/SET_TASKS_LIST',
  SET_TASKS: '[SAGS/GOODS_MODERATION]/SET_TASKS',
  CLEAR_TASKS: '[SAGS/GOODS_MODERATION]/CLEAR_TASKS',
  CHANGE_TASK_STATUS: '[SAGS/GOODS_MODERATION]/CHANGE_TASK_STATUS',
  SET_TASK_STATUS_CHANGED: '[SAGS/GOODS_MODERATION]/SET_TASK_STATUS_CHANGED',
  CLEAR_TASKS_LIST: '[SAGS/GOODS_MODERATION]/CLEAR_TASKS_LIST',
  ADD_COMMENTS: '[SAGS/GOODS_MODERATION]/ADD_COMMENTS',
  DELETE_COMMENT: '[SAGS/GOODS_MODERATION]/DELETE_COMMENT',
  GET_COMMENTS: '[SAGS/GOODS_MODERATION]/GET_COMMENTS',
  SET_COMMENTS: '[SAGS/GOODS_MODERATION]/SET_COMMENTS',
  SET_REJECTED_LANGS: '[SAGS/GOODS_MODERATION]/SET_REJECTED_LANGS',
  GET_TASK_ITEM: '[SAGS/GOODS_MODERATION]/GET_TASK_ITEM',
  SET_TASK_ITEM: '[SAGS/GOODS_MODERATION]/SET_TASK_ITEM',
  ASSIGN_TASK: '[SAGS/GOODS_MODERATION]/ASSIGN_TASK',
  GET_REPORT: '[SAGS/GOODS_MODERATION]/GET_REPORT',
  SET_ASSIGNED_TASK_WITH_ERROR:
    '[SAGS/GOODS_MODERATION]/SET_ASSIGNED_TASK_WITH_ERROR',
  GET_MODERATORS_LIST: '[SAGS/MODERATION]/GET_MODERATORS_LIST',
  SET_MODERATORS_LIST: '[SAGS/MODERATION]/SET_MODERATORS_LIST',
} as const;

/**
 * @deprecated
 * use named import instead
 * `import {types} from '...'`
 * */
const defaultExport = types;

// eslint-disable-next-line import/no-default-export
export default defaultExport;
