import React, { useRef, useEffect } from 'react';

import { throttle } from 'lodash/fp';

import styles from './with-scroll-close.module.scss';

type WithScrollCloseProps = {
  handleClose: () => void;
  children: React.ReactNode;
};

export const WithScrollClose = ({
  handleClose,
  children,
}: WithScrollCloseProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const startScroll = useRef(window.scrollY);
  const minScroll = 5;

  const handleScroll = throttle(250, (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      const currentScrollY = window.scrollY;
      const scrollDiff = currentScrollY - startScroll.current;

      if (scrollDiff > minScroll) {
        handleClose();
      }
    }
  });

  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);

    return () => document.removeEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <div className={styles.wrapper} ref={ref}>
      {children}
    </div>
  );
};
