import fp from 'lodash/fp';

import { Types, types } from '../types';

import { Language, Name } from '.';

type Enum<T = string> = {
  code: T;
  translations: Name;
}[];

type EnumNamedItem<T = string> = {
  code: T;
  name: Name;
};

type EnumGtins = {
  gtin: string;
  product_name: Name;
}[];

type EnumTnved = EnumNamedItem &
  {
    acceptable: boolean;
    validity_date: string;
    product_group: string;
  }[];

export type EnumsState = Record<string, Enum> & {
  gtins: EnumGtins;
  isGtinsLoading: false;
  lastGtinPage: boolean;
  tnved: EnumTnved;
  nationalClassifier: { code: string; name: Name }[];
  package_type: Enum<'inner_pack' | 'trade_unit'>;
  language: Enum<Language>;
  product_attribute_group: Enum<
    | 'basic_info'
    | 'characteristics'
    | 'package'
    | 'med_pack_info'
    | 'producer'
    | 'org_claims'
    | 'licenses_doc'
    | 'photo'
    | 'identification'
    | 'classification'
    | 'reg_sert'
    | 'reg_docs'
    | 'store_and_use'
  >;
  product_status: Enum<'blocked' | 'draft' | 'published' | 'on_moderation'>;
  productGroupsEnum: (EnumNamedItem & { id: number })[];
  productCategoriesEnum: EnumNamedItem[];
  photo_angle: EnumNamedItem[];
  moderators: EnumNamedItem[];
};

const initialState: EnumsState = {
  gtins: [],
  isGtinsLoading: false,
  lastGtinPage: false,
  tnved: [],
  nationalClassifier: [],
  package_type: [],
  language: [],
  product_attribute_group: [],
  product_status: [],
  productGroupsEnum: [],
  productCategoriesEnum: [],
  photo_angle: [],
  moderators: [],
};

export type EnumsActionType = Types[keyof Pick<
  Types,
  | 'TNVED_SET_LIST'
  | 'NATIONAL_SET_LIST'
  | 'ENUM_SET_LIST'
  | 'SET_GTINS'
  | 'SET_GTINS_LOADING'
  | 'SET_GTINS_ERROR'
  | 'CLEAR_GTINS'
>];

type EnumsAction = {
  type: EnumsActionType;
  payload: unknown;
};

export const reducer = (state = initialState, action: EnumsAction) => {
  switch (action.type) {
    case types.TNVED_SET_LIST:
      return {
        ...state,
        tnved: action.payload,
      };
    case types.NATIONAL_SET_LIST:
      return {
        ...state,
        nationalClassifier: action.payload,
      };
    case types.SET_GTINS:
      return {
        ...state,
        gtins: fp.uniqBy('id', [...state.gtins, ...action.payload.values]),
        lastGtinPage: action?.payload?.values?.length < 10,
        isGtinsLoading: false,
      };
    case types.SET_GTINS_LOADING:
      return {
        ...state,
        isGtinsLoading: true,
      };
    case types.SET_GTINS_ERROR:
      return {
        ...state,
        isGtinsLoading: false,
      };
    case types.CLEAR_GTINS:
      return {
        ...state,
        gtins: [],
        lastGtinPage: false,
      };
    case types.ENUM_SET_LIST:
      return {
        ...state,
        [action.payload.name]: [...action.payload.values],
      };
    case types.SET_MODERATORS_LIST:
      return {
        ...state,
        moderators: [...action.payload.values],
      };
    default:
      return state;
  }
};
